<template>
  <div class="container">
    <h3 class="text-center mt-5 mb-5">Приказы ППС</h3>
    <Button class="mb-3" @click="modal_visible = true">Создать приказ</Button>

    <PrimePreloader v-if="loading"/>
    <div v-else>
      <DataTable :value="ordersList" tableStyle="min-width: 50rem" showGridlines stripedRows paginator :rows="10">
        <Column field="name" header="Номер приказа"></Column>
        <Column field="date" header="Дата приказа"></Column>
        <Column header="Тип приказа">
          <template #body="{data}">
            {{ ordersTypes.find(i => i.id === data.type_order_id).name }}
          </template>
        </Column>
        <Column header="Преподаватели">
          <template #body="{data}">
            <router-link :to="`teachers-orders-members?order_id=` + data.id">Список преподавателей</router-link>
          </template>
        </Column>
        <Column header="Статус">
          <template #body="{data}">
            <p v-if="data.is_confirm_status == null">Не утверждено</p>
            <p v-else-if="data.is_confirm_status == 1">Утверждено</p>
            <p v-else-if="data.is_confirm_status == 2">Отказано</p>
          </template>
        </Column>
        <Column>
          <template #header>
            PDF
          </template>
          <template #body="{data}">
            <a :href="'https://back.uib.kz/test/test/print-order?id=' + data.id" target="_blank">
              <img src="@/assets/pdf.png" alt="PDF Icon" style="width: 20%; height: 20%"/>
            </a>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <Dialog v-model:visible="modal_visible" modal header="Создать приказ" :style="{ width: '50vw' }">
    <div class="col-md-12 mt-4">
      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Номер приказа</label>
        <div class="col-md-9">
          <InputText v-model="data.name" class="form-control"></InputText>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Дата приказа</label>
        <div class="col-md-9">
          <Calendar v-model="date" class="form-control"></Calendar>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Тип приказа</label>
        <div class="col-md-9">
          <Dropdown v-model="data.type_order_id" optionLabel="name" optionValue="id" :options="ordersTypes"
                    class="form-control"></Dropdown>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Текст приказа (на русском)</label>
        <div class="col-md-9">
          <Textarea v-model="data.text_ru" class="form-control"></Textarea>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Текст приказа (на казахском)</label>
        <div class="col-md-9">
          <Textarea v-model="data.text_kz" class="form-control"></Textarea>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Текст приказа (на англйиском)</label>
        <div class="col-md-9">
          <Textarea v-model="data.text_en" class="form-control"></Textarea>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Основание приказа (на русском)</label>
        <div class="col-md-9">
          <Textarea v-model="data.basis_ru" class="form-control"></Textarea>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Основание приказа (на казахском)</label>
        <div class="col-md-9">
          <Textarea v-model="data.basis_kz" class="form-control"></Textarea>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Основание приказа (на англйиском)</label>
        <div class="col-md-9">
          <Textarea v-model="data.basis_en" class="form-control"></Textarea>
        </div>
      </div>
    </div>

    <template #footer>
      <Button label="Сохранить" @click="submitOrder"></Button>
    </template>
  </Dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: 'TeachersOrders',
  data() {
    return {
      loading: true,
      modal_visible: false,
      date: null,
      data: {
        name: null,
        date: null,
        type_order_id: null,
        text_ru: null,
        text_kz: null,
        text_en: null,
        basis_ru: null,
        basis_en: null,
        basis_kz: null,
      },
    }
  },
  computed: {
    ...mapState('teachersOrders', ['ordersTypes', 'ordersList'])
  },
  watch: {
    date: function () {
      const originalDate = new Date(this.date);
      const day = originalDate.getDate();
      const month = originalDate.getMonth() + 1;
      const year = originalDate.getFullYear();

      const formattedDay = String(day).padStart(2, "0");
      const formattedMonth = String(month).padStart(2, "0");
      const formattedYear = String(year);

      this.data.date = `${formattedYear}-${formattedMonth}-${formattedDay}`;
    }
  },
  methods: {
    ...mapActions('teachersOrders', ['GET_ORDERS_TYPES', 'POST_ORDER', 'GET_ORDERS']),

    async submitOrder() {
      if (await this.POST_ORDER(this.data) === 200) {
        this.$message({title: "Данные успешно сохранились!"})
        await this.GET_ORDERS();
      }
      this.modal_visible = false
    }
  },
  async mounted() {
    await this.GET_ORDERS_TYPES();
    await this.GET_ORDERS();
    this.loading = false
  },
}
</script>